import React, { useEffect, useState } from 'react'
import { Logo, WordMark } from './icons'
import Animation from './animation'

const Loading = () => {
  const [wordmark, setWordmark] = useState(true)
  const [show, setShow] = useState(true)

  useEffect(() => {

    const onResize = () => {
      const vh = window.innerHeight * 0.01;
      document?.documentElement?.style.setProperty('--vh', vh + 'px');
    }

    onResize()
    window.addEventListener('resize', onResize)
    // add class to body to prevent scrolling
    setTimeout(() => {
      document.body.classList.add('no-scroll')
    }, 200)

    setTimeout(() => {
      setWordmark(false)
    }, 2000)
    setTimeout(() => {
      setShow(false)
      document.body.classList.remove('no-scroll')
    }, 4000)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <section className={`loading${!show ? ' loading--hide' : ''}`}>
      <div className='loading__wheel'>
        <Animation />
      </div>
      <div className={`loading__logo ${wordmark ? 'loading__logo--wordmark' : ''}`}>
        <WordMark />
        <Logo />
      </div>
    </section>
  )
}

export default Loading