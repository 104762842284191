import React, { useEffect, createRef } from 'react'
import lottie from 'lottie-web'

const Animation = () => {

  const animationContainer = createRef()
  const animation = '/animations/animation.json'

  useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      path: animation,
      rendererSettings: {
        progressiveLoad: true
      }
    })
  })

  return (
    <div className='animation'>
      <div ref={animationContainer} />
    </div>
  )
}

export default Animation