import React, { Component } from 'react'
import Link from '../utils/link'
import { navigate } from 'gatsby'
import { Logo } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    document.querySelector('body').classList.toggle('offcanvas-active', !this.state.offCanvas)
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _scrollToView = (e) => {
    if (this.props.location.pathname !== '/') navigate('/')
    e.preventDefault()
    this.setState({ offCanvas: false })
    document.querySelector('body').classList.remove('offcanvas-active')
    let section = document.getElementById(e.target.hash.replace('#', ''))
    section && section.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  render() {
    let { offCanvas } = this.state

    return (
      <>
        <header className='header'>
          <div className='header__inner'>
            <div className='header__logo'>
              <Link to='/'>
                <Logo/>
              </Link>
            </div>

            <div className='header__hamburger'>
              <button onClick={ this._toggleOffCanvas }>{ offCanvas ? 'Close' : 'Menu' }</button>
            </div>

            <div className='header__menu'>
              <nav>
                <ul>
                  <li><Link onClick={ this._scrollToView } to='#team'>People</Link></li>
                  <li><Link onClick={ this._scrollToView } to='#services'>Services</Link></li>
                  <li><Link onClick={ this._scrollToView } to='#approach'>Approach</Link></li>
                  <li><Link onClick={ this._scrollToView } to='#contact'>Contact</Link></li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
        <div className={ `off-canvas ${ offCanvas && 'off-canvas--active' }` }>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link onClick={ this._scrollToView } to='/#team'>People</Link></li>
                <li><Link onClick={ this._scrollToView } to='/#services'>Services</Link></li>
                <li><Link onClick={ this._scrollToView } to='/#approach'>Approach</Link></li>
                <li><Link onClick={ this._scrollToView } to='/#contact'>Contact</Link></li>
              </ul>
            </nav>

            <div className='off-canvas__info'>
              <Link to='tel:+61386572888'>03 8657 2888</Link>
              <Link to='mailto:admin@ac-fs.com.au'>admin@ac-fs.com.au</Link>
              <Link to='https://www.linkedin.com/company/associatedconcepts/about/'>Linkedin</Link>

              <p>Suite 4.03, Pacific Tower <br />
                737 Burwood Road <br />
                Hawthorn VIC 3122 <br />
                Australia</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
